import { legacyFrameworkJsFiles } from './legacy-framework-file-registry';
const legacyResourceFileBasePath = '/assets/legacy-framework-files';
/**
 * Loads both shared JavaScript/CSS resource files and app module files sequentially.
 * Ensures that CSS is isolated within the Shadow DOM for the legacy app.
 */
export function loadLegacyResources() {
    /**
     * Loads a list of JS files sequentially.
     *
     * @param jsFiles - List of JS file URIs to be loaded.
     */
    const loadJsFiles = (jsFiles) => {
        return jsFiles.reduce((promiseChain, fileUri) => {
            return promiseChain.then(() => loadJsFile(`${legacyResourceFileBasePath}/${fileUri}`, document.head));
        }, Promise.resolve());
    };
    disableAmdLoader();
    return loadJsFiles(legacyFrameworkJsFiles)
        .then(() => {
        enableAmdLoader();
        console.log('All legacy resources loaded');
    });
}
/**
 * Loads a JS file by creating a <script> element and appending it to the specified DOM element.
 *
 * @param uri - The URI of the JS file.
 * @param hostElement - The DOM element where the <script> will be appended.
 * @returns Promise that resolves when the file is successfully loaded or rejects on error.
 */
function loadJsFile(uri, hostElement) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = uri;
        script.onload = () => resolve(); // Resolve when the file is successfully loaded
        hostElement.appendChild(script); // Append the <script> element to the DOM
    });
}
/**
 * Disable AMD loader for compatibility with legacy AngularJS resources.
 */
function disableAmdLoader() {
    if (typeof globalThis.define === 'function' && globalThis.define.amd) {
        originalAmdLoader = globalThis.define; // Backup the original AMD loader
        delete globalThis.define; // Disable AMD loading
    }
}
/**
 * Re-enable AMD loader after legacy resources have been loaded.
 */
function enableAmdLoader() {
    if (!globalThis.define && originalAmdLoader) {
        globalThis.define = originalAmdLoader; // Restore the original AMD loader
    }
}
let originalAmdLoader;
