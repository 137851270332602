/**
 * The list of JavaScript and CSS files that are necessary for an MFE app that uses legacy frameworks
 * to operate successfully when running as an MFE app within the AO Portal micro frontend framework.
 * If one or more legacy MFE apps are registered for a client, these files are loaded during the
 * initialization process.
 */
export const legacyFrameworkJsFiles = [
    'ao-uui-jquery-external-framework.js',
    'ao-uui-angular-external-framework.js',
    'ao-uui-kendo-external-framework.js',
    'ao-uui-misc-external-framework.js'
];
